<template>
  <div class="catalog-sidebar">
    <b-overlay
      v-if="loading"
      show
      spinner-variant="primary"
      variant="light"
      class="catalog-sidebar__overlay"
    />
    <div class="catalog-sidebar__body">
      <div
        v-if="!isNoOffers"
        class="catalog-sidebar__items"
      >
        <item-catalog-menu-sidebar
          v-for="(item, index) in tree"
          :key="'km' + index"
          :item="item"
        />
      </div>
      <div
        v-else-if="!loading"
        class="catalog-sidebar__message"
      >
        <b-alert
          variant="primary"
          show
        >
          <div class="alert-body">
            У вас нет категорий, содержащих ваши предложения!
          </div>
        </b-alert>
      </div>
    </div>
    <div class="catalog-sidebar__footer" />
  </div>
</template>
<script>
import {
  BOverlay, BAlert,
} from 'bootstrap-vue'
import listToTree from 'list-to-tree-lite'
import {
  computed,
} from 'vue'
import { useSidebar } from '../composables/useCatalog'
import ItemCatalogMenuSidebar from './ItemCatalogMenuSidebar.vue'

export default {
  name: 'CatalogMenuSidebar',
  components: {
    BOverlay,
    BAlert,

    ItemCatalogMenuSidebar,
  },
  setup() {
    const {
      // CurrentCategory
      currentCategory,

      // Categories
      categories,

      // Meta
      breadcrumb,

      // Base
      loading,

      // Catalog
      isNoOffers,
    } = useSidebar()

    const items = computed(() => {
      const res = []

      if (currentCategory.value) {
        if (currentCategory.value.fake) {
          res.push({
            ...currentCategory.value,
            is_current: true,
          })
        } else {
          res.push({
            ...currentCategory.value,
            parent_id: currentCategory.value.parent_id ? currentCategory.value.parent_id : 'root',
            is_current: true,
          })
        }

        if (currentCategory.value.parent_categories && Array.isArray(currentCategory.value.parent_categories)) {
          currentCategory.value.parent_categories.forEach(i => {
            const category = { ...i }

            if (!i.fake) {
              category.parent_id = i.parent_id ? i.parent_id : 'root'
            }

            res.push(category)
          })
        }
      }

      if (categories.value && Array.isArray(categories.value)) {
        categories.value.forEach(ch => {
          const children = { ...ch, is_children: true }
          if (!ch.fake) {
            children.parent_id = ch.parent_id ? ch.parent_id : 'root'
          }

          res.push(children)
        })
      }

      return res
    })

    const tree = computed(() => listToTree(items.value, {
      idKey: 'id',
      parentKey: 'parent_id',
    }))

    return {
      // CurrentCategory
      currentCategory,

      // Categories
      categories,

      // Meta
      breadcrumb,

      // Base
      loading,

      // Catalog
      isNoOffers,

      tree,
      items,
    }
  },
}
</script>

<style scoped lang="scss">
@import '~@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '~@core/scss/base/components/include'; // Components includes

.catalog-sidebar{
  width: 219px;
  background: $white;
  min-height: 100%;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: stretch;
  padding: 16px;
  position: relative;

  &__overlay{
    // height: 100%;
    position: absolute !important;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }
}
</style>
