<template>
  <hls-layout class="catalog-page">
    <template #header>
      <header-partial>
        <template #center>
          <div
            v-if="showSearchField"
            class="d-flex align-items-center justify-content-end"
          >
            <b-form-input
              v-model.trim="searchQuery"
              class="d-inline-block"
              placeholder="Поиск..."
            />
          </div>
        </template>
        <template #right>
          <b-button
            v-if="$store.getters['workingMode/checkPermission']('manage_product_offers')"
            variant="outline-primary"
            pill
            @click="$router.push({ name: 'global-catalog'})"
          >
            Добавить
          </b-button>
        </template>
      </header-partial>
    </template>
    <template #sidebar>
      <catalog-menu-sidebar />
    </template>
    <b-overlay
      :show="loading || offerLoading"
      spinner-variant="primary"
      class="catalog-page__overlay"
    >

      <app-breadcrumb
        :breadcrumb="breadcrumb"
      />

      <template v-if="!loading">
        <div
          v-if="isNoOffers"
          class="catalog-page__content__empty"
        >
          <b-alert
            variant="primary"
            show
          >
            <div class="alert-body">
              У вас нет предложений!
            </div>
          </b-alert>
          <b-button
            variant="primary"
            class="mt-1"
            @click="$router.push({ name: 'global-catalog'})"
          >
            Добавить
          </b-button>
        </div>
        <template v-else-if="Array.isArray(categories) && categories.length">
          <b-card
            no-body
          >
            <div class="m-2">
              <b-row align-v="center">
                <b-col
                  cols="12"
                  md=""
                >
                  <h4 class="mb-0">
                    {{ currentCategory.id === 'root' ? 'Категории' : 'Подкатегории' }}
                  </h4>
                </b-col>
                <b-col
                  cols="12"
                  md="auto"
                >
                  <!-- Item View Radio Button Group  -->
                  <b-form-radio-group
                    v-model="categoryViewSelectedType"
                    class="list item-view-radio-group"
                    buttons
                    size="sm"
                    button-variant="outline-primary"
                  >
                    <b-form-radio
                      v-for="option in categoryViewOptions"
                      :key="option.value"
                      :value="option.value"
                    >
                      <feather-icon
                        :icon="option.icon"
                        size="18"
                      />
                    </b-form-radio>
                  </b-form-radio-group>
                </b-col>
              </b-row>
            </div>

            <category-list-table
              v-if="categoryViewSelectedType === 'list-view'"
              :categories="categories"
            />
          </b-card>
          <div
            v-if="categoryViewSelectedType === 'card-view'"
            class="catalog-page__categories"
          >
            <b-row>
              <b-col
                v-for="(category, category_index) in categories"
                :key="'sc' + category_index"
                cols="6"
                sm="6"
                md="4"
                lg="3"
                xl="2"
              >
                <category-card
                  class="catalog-page__category"
                  :category="category"
                />
              </b-col>
            </b-row>
          </div>
        </template>
      </template>
      <template v-if="!offerLoading && Array.isArray(offers) && offers.length">
        <!-- BEGIN Сортировка -->
        <b-card
          no-body
        >
          <div class="mx-2 my-1">
            <!-- Table Top -->
            <b-row align-v="center">
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Показать</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>элементов</label>
              </b-col>

              <!-- Search -->
              <b-col
                cols="12"
                md="6"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <label>Сортировать по</label>
                  <div class="catalog-page__sort">
                    <v-select
                      v-model="sortBy"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="sortFields"
                      :clearable="false"
                      class="catalog-page__sort__select d-inline-block mx-50"
                      label="label"
                      :reduce="option => option.value"
                    />
                    <b-button
                      variant="outline-secondary"
                      class="btn-icon catalog-page__sort__sort-dir mr-50"
                      :class="{'catalog-page__sort__sort-dir_active': isSortDirDesc}"
                      @click="isSortDirDesc = !isSortDirDesc"
                    >
                      <feather-icon icon="BarChartIcon" />
                    </b-button>

                    <b-form-radio-group
                      v-model="productViewSelectedType"
                      class="list item-view-radio-group catalog-page__product-s-view"
                      buttons
                      size="sm"
                      button-variant="outline-primary"
                    >
                      <b-form-radio
                        v-for="option in productViewOptions"
                        :key="'pwo' + option.value"
                        :value="option.value"
                      >
                        <feather-icon
                          :icon="option.icon"
                          size="18"
                        />
                      </b-form-radio>
                    </b-form-radio-group>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-card>
        <!-- END Сортировка -->

        <!-- BEGIN Offers -->
        <div
          v-if="productViewSelectedType === 'card-view'"
          class="catalog-page__offers"
        >
          <div
            v-for="(offer, index_offer) in offers"
            :key="'o' + index_offer"
          >
            <offer-card
              class="catalog-page__offer"
              :offer="offer"
              @delete="deleteOffer"
            />
          </div>
        </div>

        <b-card
          v-if="productViewSelectedType === 'list-view'"
          no-body
        >
          <b-table
            ref="refCompanyListTable"
            class="position-relative list-view-table mb-0"
            :items="offers"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="Ничего не найдено!"
            :sort-desc.sync="isSortDirDesc"
            no-local-sorting
          >
            <template #cell(id)="data">
              <small>{{ data.item.id }}</small>
            </template>

            <template #cell(product_id)="data">
              <b-link :to="{ name: 'offers.show', params: { id: data.item.id } }">
                {{ get(data.item, 'product.name') }}
              </b-link>
            </template>

            <template #cell(price)="data">
              <div
                class="small"
                style="text-decoration: line-through;"
              >
                {{ Number(get(data.item, 'sale_price', '')) ? Number(get(data.item, 'price', '')) : '' }}
              </div>
              <div class="small strong">
                {{ Number(get(data.item, 'sale_price', '')) || Number(get(data.item, 'price', '')) }}
              </div>
            </template>

            <template #cell(product_warehouse_remains)="data">
              <small>
                {{ getWarehouseRemainsTotalByOffer(data.item) }} /
                {{ getWarehouseRemainsReserveByOffer(data.item) }}
              </small>
            </template>

            <template #cell(is_active)="data">
              <b-badge
                pill
                variant=""
                class="text-capitalize"
                :variant="data.item.is_active ? 'light-success' : 'light-secondary'"
              >
                {{ data.item.is_active ? 'Вкл' : 'Выкл' }}
              </b-badge>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <div class="text-nowrap">
                <b-button
                  v-if="activeBasketBtn(data.item)"
                  :id="`offer-row-${data.item.id}-basket-button`"
                  variant="link"
                  class="btn-icon"
                  @click="$store.dispatch('basket/showModal', data.item)"
                >
                  <feather-icon
                    icon="ShoppingCartIcon"
                  />
                </b-button>

                <b-tooltip
                  title="Добавить в корзину"
                  class="cursor-pointer"
                  :target="`offer-row-${data.item.id}-basket-button`"
                />

                <b-dropdown
                  variant="link"
                  no-caret
                  toggle-class="p-0"
                  :right="$store.state.appConfig.isRTL"
                >

                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item :to="{ name: 'offers.show', params: { id: data.item.id } }">
                    <feather-icon icon="FileTextIcon" />
                    <span class="align-middle ml-50">Посмотреть</span>
                  </b-dropdown-item>

                  <template v-if="$store.getters['workingMode/checkPermission']('manage_product_offers')">
                    <b-dropdown-item :to="{ name: 'offers.edit', params: { id: data.item.id } }">
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">Изменить</span>
                    </b-dropdown-item>

                    <b-dropdown-item>
                      <feather-icon
                        icon="TrashIcon"
                        @click="deleteOffer()"
                      />
                      <span class="align-middle ml-50">Удалить</span>
                    </b-dropdown-item>
                  </template>
                </b-dropdown>
              </div>

            </template>
          </b-table>
        </b-card>

        <!-- END Offers -->

        <!-- BEGIN Pagination -->
        <b-card
          no-body
          class="mt-2"
        >
          <div class="mx-2 my-1">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">Показано с {{ from }} по {{ to }} из {{ totalItems }}</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalItems"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
        <!-- END Pagination -->
      </template>
    </b-overlay>

  </hls-layout>
</template>

<script>
import {
  BButton,
  BAlert,
  BOverlay,
  BRow,
  BCol,

  BCard, BFormInput, BPagination, BFormRadio, BFormRadioGroup, BTable, BLink, BBadge, BDropdown, BDropdownItem,
  BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import HlsLayout from '@/components/sub-layout/HlsLayout.vue'
import HeaderPartial from '@/components/partials/HeaderPartial.vue'
import AppBreadcrumb from '@/layouts/components/AppBreadcrumb.vue'
import { getNoun } from '@/services/stringHelper'
import useLocalStorage from '@/composables/useLocalStorage'
import { ref } from 'vue'
import get from 'lodash/get'
import CatalogMenuSidebar from '../components/CatalogMenuSidebar.vue'
import useCatalog from '../composables/useCatalog'
import CategoryCard from '../components/CategoryCard.vue'
import OfferCard from '../components/OfferCard.vue'
import CategoryListTable from '../components/CategoryListTable.vue'

export default {
  name: 'CatalogPage',
  components: {
    BDropdownItem,
    BDropdown,
    BBadge,
    BLink,
    BTable,
    BFormRadioGroup,
    BFormRadio,
    OfferCard,
    CategoryCard,
    HeaderPartial,
    HlsLayout,
    AppBreadcrumb,
    CatalogMenuSidebar,

    BButton,
    BAlert,
    BOverlay,
    BRow,
    BCol,

    BCard,
    BFormInput,
    BPagination,

    vSelect,
    CategoryListTable,
    BTooltip,
  },
  setup() {
    // BEGIN SwitchViewCategory
    const categoryViewSelectedType = useLocalStorage('subcategories-view', 'list-view')
    const categoryViewOptions = [
      { icon: 'ListIcon', value: 'list-view' },
      { icon: 'GridIcon', value: 'card-view' },
    ]
    // END SwitchViewCategory

    // BEGIN SwitchViewProduct
    const productViewSelectedType = useLocalStorage('products-view', 'list-view')
    const productViewOptions = [
      { icon: 'ListIcon', value: 'list-view' },
      { icon: 'GridIcon', value: 'card-view' },
    ]
    // END SwitchViewProduct

    // BEGIN ForOffersTable
    const tableColumns = ref([
      { key: 'id', label: 'ID' },
      { key: 'product_id', label: 'Название' },
      { key: 'price', label: 'Цена' },
      { key: 'is_active', label: 'Активность' },
      { key: 'product_warehouse_remains', label: 'Остатки / Резерв' },
      {
        key: 'actions', label: 'Действия', tdClass: 'text-right', thClass: 'text-right',
      },
    ])

    const getWarehouseRemainsTotalByOffer = offerObject => {
      const warehouseRemains = get(offerObject, 'product_warehouse_remains', [])
      const count = warehouseRemains.reduce((sum, item) => sum + item.total, 0)

      return count
    }

    const getWarehouseRemainsReserveByOffer = offerObject => {
      const warehouseRemains = get(offerObject, 'product_warehouse_remains', [])
      const count = warehouseRemains.reduce((sum, item) => sum + item.reserve, 0)

      return count
    }

    const activeBasketBtn = offerObject => (getWarehouseRemainsTotalByOffer(offerObject) - getWarehouseRemainsReserveByOffer(offerObject)) > 0 && get(offerObject, 'is_active', false)
    // END ForOffersTable

    const {
      // Base
      loading,

      // CurrentCategory
      currentCategory,

      // Categories
      categories,

      // Products,
      products,

      // Offers
      offers,
      offerLoading,
      perPageOptions,
      perPage,
      from,
      to,
      currentPage,
      totalItems,
      deleteOffer,

      sortBy,
      searchQuery,
      isSortDirDesc,
      sortFields,

      // Meta
      breadcrumb,

      // Catalog
      params,
      isNoOffers,
      showSearchField,
    } = useCatalog()

    return {
      // Base
      loading,

      // CurrentCategory
      currentCategory,

      // Categories
      categories,

      // Products,
      products,

      // Offers
      offers,

      offerLoading,
      perPageOptions,
      perPage,
      from,
      to,
      currentPage,
      totalItems,
      deleteOffer,

      sortBy,
      searchQuery,
      isSortDirDesc,
      sortFields,

      // Meta
      breadcrumb,

      // Catalog
      isNoOffers,
      params,

      // Other
      getNoun,
      showSearchField,

      categoryViewSelectedType,
      categoryViewOptions,
      productViewSelectedType,
      productViewOptions,

      tableColumns,

      get,

      activeBasketBtn,
      getWarehouseRemainsReserveByOffer,
      getWarehouseRemainsTotalByOffer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.content-header .breadcrumb {
  display: flex;
}
</style>

<style scoped lang="scss">
.per-page-selector {
  width: 90px;
}
.list-view-table{
  min-height: 145px;
}

.catalog-page{
  &__content{

  }

  &__category{
    margin-bottom: 30px;
  }

  &__categories{
    // margin-bottom: -30px;
  }

  &__offers{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    grid-gap: 30px;
    grid-row-gap: 30px;
  }

  &__sort{
    display: flex;

    &__sort-dir{
      svg{
        transform: rotate(90deg);
      }
      &_active{
        svg{
          transform: rotate(90deg) rotateZ(180deg);
        }
      }
    }

    &__select{
      width: 138px;
    }
  }

  &__overlay{
    min-height: 100%;
  }

  &__product-s-view{
    label{
      display: flex;
      align-content: center;
      align-items: center;
    }
  }
}
</style>
