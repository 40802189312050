<template>
  <div class="offer-card">
    <div class="offer-card__labels">
      <b-badge
        class="offer-card__label"
        :variant="active ?'success' : 'secondary'"
        size="sm"
        pill
      >
        <template v-if="active">
          Вкл
        </template>
        <template v-else>
          Выкл
        </template>
      </b-badge>
    </div>
    <b-dropdown
      variant="link"
      no-caret
      right
      class="offer-card__dropdown-menu"
      boundary="body"
    >
      <template #button-content>
        <feather-icon
          icon="MoreHorizontalIcon"
          size="16"
          class="align-middle text-body"
        />
      </template>

      <b-dropdown-item :to="{ name: 'offers.show', params: { id: id } }">
        <feather-icon icon="FileTextIcon" />
        <span class="align-middle ml-50">Посмотреть</span>
      </b-dropdown-item>

      <template v-if="$store.getters['workingMode/checkPermission']('manage_product_offers')">
        <b-dropdown-item :to="{ name: 'offers.edit', params: { id: id } }">
          <feather-icon icon="EditIcon" />
          <span class="align-middle ml-50">Изменить</span>
        </b-dropdown-item>

        <b-dropdown-item @click="$emit('delete', offer)">
          <feather-icon icon="TrashIcon" />
          <span class="align-middle ml-50">Удалить</span>
        </b-dropdown-item>
      </template>
    </b-dropdown>
    <b-link
      :to="{ name: 'offers.show', params: { id: id } }"
      class="offer-card__image-container"
    >
      <img
        v-if="image"
        :src="image"
        :alt="name"
      >
      <feather-icon
        v-else
        icon="ImageIcon"
        class="offer-card__img-placeholder"
      />
    </b-link>
    <div class="offer-card__info">
      <div class="offer-card__prices-and-rating">
        <div class="offer-card__rating-and-reviews">
          <div class="offer-card__rating">
            <ul class="unstyled-list list-inline">
              <li
                v-for="star in 5"
                :key="star"
                class="ratings-list-item"
                :class="{'ml-25': star-1}"
              >
                <feather-icon
                  icon="StarIcon"
                  size="16"
                  :class="[{'fill-current': star <= rating}, star <= rating ? 'text-warning' : 'text-muted']"
                />
              </li>
            </ul>
          </div>
          <div class="offer-card__reviews">
            {{ countReviews }} {{ getNoun(countReviews, 'отзыв', 'отзыва', 'отзывов') }}
          </div>
        </div>
        <div class="offer-card__prices">
          <div class="offer-card__prices__now">
            {{ price_now.toLocaleString() }} ₽
          </div>
          <div
            v-if="price_old"
            class="offer-card__prices__old"
          >
            {{ price_old.toLocaleString() }} ₽
          </div>
        </div>
      </div>
      <b-link
        :to="{ name: 'offers.show', params: { id: id } }"
        class="offer-card__name"
      >
        {{ name }}
      </b-link>
      <div class="row no-gutters">
        <div class="col">
          <div class="offer-card__count">
            <div class="">
              Остаток:
            </div>
            <div
              v-if="count"
              class="text-success"
            >
              {{ count }} шт.
            </div>
            <div
              v-else
              class="text-danger"
            >
              Нет
            </div>
          </div>
        </div>
        <div class="col">
          <div class="offer-card__count">
            <div class="">
              Резерв:
            </div>
            <div
              v-if="reserve"
              class="text-success"
            >
              {{ reserve }} шт.
            </div>
            <div
              v-else
              class="text-danger"
            >
              Нет
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="offer-card__actions">
      <!--      <b-button-->
      <!--        variant="light"-->
      <!--        tag="a"-->
      <!--        class="btn-wishlist"-->
      <!--      >-->
      <!--        <feather-icon-->
      <!--          icon="HeartIcon"-->
      <!--          class="mr-50"-->
      <!--          :class="{'text-danger': false}"-->
      <!--        />-->
      <!--        <span>Wishlist</span>-->
      <!--      </b-button>-->
      <b-button
        variant="primary"
        tag="a"
        class="btn-cart"
        :disabled="!activeBasketBtn"
        @click="addToBasket"
      >
        <feather-icon
          icon="ShoppingCartIcon"
          class="mr-50"
        />
        <span>Добавить</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BBadge, BButton, BDropdown, BDropdownItem, BLink,
} from 'bootstrap-vue'
import get from 'lodash/get'
import { getNoun } from '@/services/stringHelper'

export default {
  name: 'OfferCard',
  components: {
    BLink,
    BButton,
    BBadge,
    BDropdown,
    BDropdownItem,
  },
  props: {
    offer: {
      type: Object,
      required: true,
    },
  },
  computed: {
    id() {
      return get(this.offer, 'id', null)
    },
    image() {
      return get(this.offer, 'product.images[0].path', null)
    },
    active() {
      return get(this.offer, 'is_active', false)
    },
    name() {
      return get(this.offer, 'product.name', '')
    },
    rating() {
      return 0
    },
    countReviews() {
      return 0
    },
    price_now() {
      const actionPrice = Number(get(this.offer, 'sale_price', ''))
      const price = Number(get(this.offer, 'price', ''))

      return actionPrice || price
    },
    price_old() {
      const actionPrice = Number(get(this.offer, 'sale_price', ''))
      const price = Number(get(this.offer, 'price', ''))

      return actionPrice ? price : null
    },
    count() {
      const warehouseRemains = get(this.offer, 'product_warehouse_remains', [])

      const count = warehouseRemains.reduce((sum, item) => sum + item.total, 0)

      return count
    },
    reserve() {
      const warehouseRemains = get(this.offer, 'product_warehouse_remains', [])

      return warehouseRemains.reduce((sum, item) => sum + item.reserve, 0)
    },
    activeBasketBtn() {
      return (this.count - this.reserve) > 0 && this.active
    },
  },
  methods: {
    getNoun,
    addToBasket() {
      this.$store.dispatch('basket/showModal', this.offer)
    },
  },
}
</script>

<style scoped lang="scss">
@import '~@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '~@core/scss/base/components/include'; // Components includes

.offer-card{
  border-radius: 6px;
  background: $card-bg;
  transition: all 0.25s ease;
  position: relative;

  // background: rgba(0, 0, 0, 0.0001);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);

  &__image-container{
    display: block;
    height: 0;
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    // background: #e2e2e2;
    border-radius: 6px 6px 0 0;
    overflow: hidden;
    img{
      position: absolute;
      top: 10px;
      right: 10px;
      left: 10px;
      bottom: 10px;
      width: calc(100% - 20px);
      height: calc(100% - 20px);
      object-fit: contain;
      object-position: center center;
    }
  }

  &__img-placeholder{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: #6E6B7B;
    width: 40%;
    height: 40%;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 25px 0 rgba($black, 0.25);
  }

  &__info{
    height: 161px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px 20px;
  }

  &__name{
    height: 63px;
    color: $headings-color;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;

    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__count{
    display: flex;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    div:nth-child(1){
      color: $secondary;
      margin-right: 5px;
    }
    div:nth-child(2),div:nth-child(3){
      font-weight: 400;
    }
  }

  &__prices-and-rating{
    height: 36px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  &__prices{
    &__now{
      color: $headings-color;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: right;
    }
    &__old{
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      text-align: right;
      text-decoration-line: line-through;

      color: $gray-100;
    }
  }

  &__reviews{
    color: $gray-100;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
  }

  &__rating{

    ul {
      margin-bottom: 0;
    }
    svg,
    i {
      height: 1.143rem;
      width: 1.143rem;
      font-size: 1.143rem;
    }
  }
  &__actions{
    display: flex;
    flex-wrap: nowrap;
    // flex-wrap: wrap;

    //background: #F8F8F8;
    //height: 42px;
    border-radius: 0 0 6px 6px;
    overflow: hidden;

    .btn-cart,
    .btn-wishlist {
      flex-grow: 1;
      border-radius: 0;
    }

    .btn-wishlist,
    .btn-cart {
      span {
        vertical-align: text-top;
      }
      i,
      svg {
        margin-right: 0.25rem;
        vertical-align: text-top;
        &.text-danger {
          fill: $danger;
        }
      }
    }
  }

  &__labels {
    position:  absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 1;
    pointer-events: none;
    padding: 16px 20px;
  }

  &__label{
    font-size: 8px;
  }

  &__dropdown-menu {
    position: absolute !important;
    right: 20px;
    top: 16px;
    z-index: 2;
    background: $card-bg;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
  }
}
</style>
<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '~@core/scss/base/components/include'; // Components includes

.offer-card{
  &__dropdown-menu{
    .dropdown-toggle{
      padding: 0;
      margin: 0;
      text-align: center;
      svg{
        color: $gray-100 !important;

      }
    }
  }
}
</style>
