<template>
  <div class="item-catalog-menu">
    <router-link
      v-slot="{navigate}"
      :to="to"
      custom
    >
      <div
        class="item-catalog-menu__item"
        :class="{
          active: item.is_current
        }"
        @click="navigate"
      >
        <div
          class="item-catalog-menu__item__name"
        >
          {{ item.name }}
        </div>
        <div
          v-if="get(item, 'children.length', 0)"
          class="item-catalog-menu__item__chevron"
        >
          <feather-icon icon="ChevronRightIcon" />
        </div>
      </div>
    </router-link>

    <div
      v-if="get(item, 'children.length', 0)"
      class="item-catalog-menu__children"
    >
      <item-catalog-menu-sidebar
        v-for="(children, index) in item.children"
        :key="'ch' + index"
        :item="children"
      />
    </div>
  </div>
</template>

<script>
import get from 'lodash/get'

export default {
  name: 'ItemCatalogMenuSidebar',
  components: {
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    to() {
      return {
        name: 'catalog',
        params: {
          category: this.item.fake ? undefined : this.item.id,
        },
      }
    },
  },
  methods: {
    get,
  },
}
</script>

<style scoped lang="scss">
@import '~@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '~@core/scss/base/components/include'; // Components includes

.item-catalog-menu{

  @keyframes moving {
    100% {transform: rotate(360deg);}
  }

  &__item{
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    cursor: pointer;
    &__name{
      font-style: normal;
      font-weight: 500;
      font-size: 13.3869px;
      line-height: 16px;
      color: $secondary;
      padding-top: 5px;
      padding-bottom: 5px;
      width: 100%;
    }
    &__chevron{
      padding-left: 5px;
      padding-right: 5px;
      color: $gray-100;
      width: auto;
      max-width: 100%;
      svg{
        transform: rotate(90deg);
      }
    }

    &.active &__name,
    &.active &__chevron,
    &:hover &__name,
    &:hover &__chevron{
      color: $primary;
    }
    &.active{
      cursor: default;
    }
  }
  &__children{
    padding-left: 10px;
  }
}
</style>
