import {
  ref, computed, onMounted, getCurrentInstance, watch,
} from 'vue'
import get from 'lodash/get'
import useCrudList from '@/composables/useCrudList'
import { getCategories as getCategoriesApi, getCategory as getCategoryApi } from '@/services/main-api/catalog/products/categories'
import { deleteProductOffer, getProductOffersByProducts, getProductOffers } from '@/services/main-api/shop/product-offers'
import store from '@/store'

// BEGIN Base
const loading = ref(false)
let instance = null
const params = computed(() => get(instance, '$route.params', {}))
// END Base

// BEGIN CurrentCategory
const rootCategory = {
  id: 'root',
  is_active: 1,
  name: 'Каталог',
  parent_id: null,
  project_id: null,
  slug: undefined,
  status: 'public',
  fake: true,
  has_children: true,
}

const currentCategory = ref(null)

const getCurrentCategory = async () => {
  const { category } = params.value
  if (!category) {
    currentCategory.value = rootCategory
  } else {
    try {
      const cCategory = (await getCategoryApi({ id: category, includes: 'parent_categories' })).data.data
      if (cCategory.parent_categories && Array.isArray(cCategory.parent_categories)) {
        cCategory.parent_categories.unshift(rootCategory)
      }
      currentCategory.value = cCategory
    } catch (e) {
      currentCategory.value = null
    }
  }
}
// END

// BEGIN Categories
const categories = ref([])

async function getCategories() {
  const { category } = params.value

  try {
    const res = await getCategoriesApi({
      project: store.getters['workingMode/selected_project_id'],
      perPage: 1000,
      sortedBy: 'asc',
      orderBy: 'name',
      page: 1,
      search: `parent_id:${category || null}`,
      searchFields: 'parent_id:=',
      includes: 'children',
      mode: 'by_product_offers',
    })
    categories.value = res.data.data
  } catch (e) {
    categories.value = []
  }

/*   */
}
// END Categories

// BEGIN Offers
async function getOffers() {
  const { category } = params.value
  if (!category) {
    instance.offers = []
    return
  }

  // let search = null
  //
  // const searchFields = []
  //
  // if (searchFields.length) {
  //   search = searchFields.join(';')
  // }

  let relationSearch = null

  const relationSearchFields = [
    `product_category_id:${category}`,
  ]

  if (instance.searchQuery) {
    relationSearchFields.push(`name:${instance.searchQuery}`)
  }

  if (relationSearchFields.length) {
    relationSearch = relationSearchFields.join(';')
  }

  try {
    const res = await getProductOffersByProducts({
      project: instance.$store.getters['workingMode/selected_project_id'],
      perPage: instance.perPage,
      sortedBy: instance.isSortDirDesc ? 'desc' : 'asc',
      orderBy: instance.sortBy,
      page: instance.currentPage,
      // search,
      relationSearch,
      searchJoin: 'and',
      relationSearchJoin: 'and',
      includes: 'product_warehouse_remains',
      // includes: 'images;product_offers;product_offers.product_warehouse_remains',
      mode: 'by_offer',
    })
    instance.offers = res.data.data
    instance.currentPage = res.data.meta.current_page
    instance.from = res.data.meta.from
    instance.to = res.data.meta.to
    instance.totalItems = res.data.meta.total
  } catch (e) {
    instance.offers = []
  }
}
const sortFields = [
  { label: 'Названию', value: 'product.name' },
  { label: 'Цене', value: 'price' },
  { label: 'Создан', value: 'created_at' },
  { label: 'Изменен', value: 'updated_at' },
]
const isNoOffers = ref(true)
async function checkHasOffers() {
  try {
    const res = await getProductOffers({
      project: instance.$store.getters['workingMode/selected_project_id'],
      perPage: 1,
      sortedBy: 'asc',
      orderBy: 'id',
      page: 1,
    })
    isNoOffers.value = !get(res, 'data.data.length', 0)
  } catch (e) {
    isNoOffers.value = true
  }
}
// END Offers

// BEGIN Meta
const breadcrumb = computed(() => {
  const items = [
    // {
    //   text: 'Каталог',
    //   active: true,
    //   to: {
    //     name: 'catalog',
    //   },
    // },
  ]

  if (
    currentCategory.value
    && currentCategory.value.parent_categories
    && Array.isArray(currentCategory.value.parent_categories)
  ) {
    currentCategory.value.parent_categories.forEach(i => {
      items.push({
        text: i.name,
        active: false,
        to: {
          name: 'catalog',
          params: {
            category: i.fake ? undefined : i.id,
          },
        },
      })
    })
  }
  //
  if (currentCategory.value) {
    items.push({
      text: currentCategory.value.name,
      active: true,
    })
  }

  return items
})
// END Meta

// BEGIN Catalog

async function getData() {
  loading.value = true
  await Promise.all([
    await getCategories(),
    await getCurrentCategory(),
    await checkHasOffers(),
  ])

  loading.value = false
}

const showSearchField = ref(false)

async function checkShowSearchField() {
  const { category } = params.value
  if (!category) {
    showSearchField.value = false
    return
  }

  if (currentCategory.value && currentCategory.value.fake) {
    showSearchField.value = false
    return
  }

  try {
    const res = await getProductOffersByProducts({
      project: instance.$store.getters['workingMode/selected_project_id'],
      perPage: 1,
      sortedBy: 'asc',
      orderBy: 'id',
      page: 1,
      relationSearch: `product_category_id:${category}`,
    })
    showSearchField.value = !!get(res, 'data.data.length', 0)
  } catch (e) {
    showSearchField.value = false
  }
}
// END Catalog

export default function useCatalog() {
  instance = getCurrentInstance().proxy

  // BEGIN Offers
  const {
    loading: offerLoading,

    perPageOptions,
    perPage,
    from,
    to,
    currentPage,
    totalItems,

    sortBy,
    searchQuery,
    isSortDirDesc,

    items: offers,
    deleteItem: deleteOffer,
  } = useCrudList({
    sortBy: 'product.name',
    getItems: getOffers,
    deleteEntity: item => deleteProductOffer(get(item, 'id')),
    getQuestionTextBeforeDeletion: item => `Предложение ${item.product.name} будет удалено`,
    getTextAfterDeletion: () => 'Предложение было удалено.',
  })

  watch(() => params.value.category, async () => {
    offerLoading.value = true
    await getOffers()
    offerLoading.value = false
  })
  // END Offers

  watch(currentCategory, async () => {
    await checkShowSearchField()
  })

  onMounted(async () => {
    await getData()
  })

  watch(params, async () => {
    await getData()
  })

  return {
    // Base
    loading,

    // CurrentCategory
    currentCategory,
    getCurrentCategory,

    // Categories
    categories,
    getCategories,

    // Offers,
    offers,
    deleteOffer,

    offerLoading,
    perPageOptions,
    perPage,
    from,
    to,
    currentPage,
    totalItems,

    sortBy,
    searchQuery,
    isSortDirDesc,
    sortFields,

    // Meta
    breadcrumb,

    // Catalog
    getData,
    isNoOffers,
    params,
    showSearchField,
  }
}

export function useSidebar() {
  return {
    // CurrentCategory
    currentCategory,

    // Categories
    categories,

    // Meta
    breadcrumb,

    // Base
    loading,

    // Catalog
    isNoOffers,

  }
}
